import { div } from '@tensorflow/tfjs';
import React, { Component } from 'react';
import './Home.css';
//import './About.css';
//import './Detection_.css';

/**
 * Class to handle the rendering of the Home page.
 * @extends React.Component
 */
export default class Home extends Component {
  render() {
    var ReactRotatingText = require('react-rotating-text');
    return (
      <div className="home_container">
        <div>
          <div className='landing_text_static'>
            CerviXpert - VIA
          </div>
          <div className='landing_text_dynamic'>
            <ReactRotatingText items={['identify cervical cancer from visual inspection with acetic acid (VIA) images.']} />
          </div>
        </div>
      </div>
    );
  }
}
