import React, { Component } from 'react';
import './About.css';

/**
 * Class to handle the rendering of the Home page.
 * @extends React.Component
 */
export default class Home extends Component {
  render() {
    return (
      <div className="About container">
        <h1>About</h1>
        <div>
        Welcome to the VIA-based cervical cancer screening app, designed to assist health workers in detecting abnormalities in cervical images. This app combines the power of artificial intelligence with a user-friendly interface to provide a seamless experience for health workers. Our app uses a trained machine learning model to analyze images captured during VIA-based screening. Health workers simply capture an image using the smartphone camera, and the app classifies the image as either positive or negative for abnormalities. This allows for efficient and accurate decision-making during the screening process. In addition to the automated analysis of cervical images, our app also provides educational resources for health workers on cervical cancer prevention and screening. We are committed to improving access to cervical cancer screening in low-resource settings, and we believe that our app can make a significant impact in the fight against cervical cancer.
        <div>
          Thank you for choosing our app for VIA-based cervical cancer screening.
        </div>
        
        </div>
      </div>
    );
  }
}
